<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Forgot Password v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <img
                        src="@/assets/images/logo/logo.png"
                        alt="Logo"
                        class="logo-auth"
                    />
                    <h2 class="brand-text text-primary ml-1">Leechall.io</h2>
                </b-link>

                <b-card-title class="mb-1"> Forgot Password? 🔒 </b-card-title>
                <b-card-text>
                    Enter your email and we'll send you instructions to reset
                    your password
                </b-card-text>
                <b-card-text class="mb-2">
                    If you do not see the email in a few minutes, check your
                    “junk mail” folder or “spam” folder.
                </b-card-text>

                <!-- alert -->
                <b-alert :variant="variant" show v-if="status">
                    <div class="alert-body">{{ status }}</div>
                </b-alert>

                <!-- form -->
                <validation-observer ref="forgotForm" #default="{ invalid }">
                    <b-form
                        class="auth-forgot-password-form mt-2"
                        @submit.prevent="onSubmit"
                    >
                        <!-- email -->
                        <b-form-group
                            label="Email"
                            label-for="forgot-password-email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="email"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="forgot-password-email"
                                    v-model="email"
                                    :state="errors.length > 0 ? false : null"
                                    name="forgot-password-email"
                                    placeholder="john@example.com"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- <vue-recaptcha
                            class="mt-1"
                            ref="reCaptcha"
                            :sitekey="sitekey"
                            @verify="onReset"
                            @expired="onExpired"
                            size="invisible"
                        /> -->

                        <vue-hcaptcha
                            class="mt-1"
                            :sitekey="sitekey"
                            size="invisible"
                            ref="hCaptcha"
                            @verify="onReset"
                            @expired="onExpired"
                        />

                        <!-- overlay -->
                        <b-overlay
                            :show="loading"
                            rounded
                            opacity="0.6"
                            spinner-variant="primary"
                        >
                            <!-- submit button -->
                            <b-button
                                variant="primary"
                                block
                                type="submit"
                                :disabled="invalid"
                            >
                                Send reset link
                            </b-button>
                        </b-overlay>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <b-link :to="{ name: 'auth-login' }">
                        <feather-icon icon="ChevronLeftIcon" /> Back to login
                    </b-link>
                </b-card-text>
            </b-card>
            <!-- /Forgot Password v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BOverlay,
    BAlert,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
    components: {
        BOverlay,
        BAlert,
        BCard,
        BLink,
        BCardText,
        BCardTitle,
        BFormGroup,
        BFormInput,
        BButton,
        BForm,
        ValidationProvider,
        ValidationObserver,
        VueRecaptcha,
        VueHcaptcha,
    },
    data() {
        return {
            sitekey: process.env.VUE_APP_HCAPTCHA,
            email: "",
            variant: "",
            status: "",
            loading: false,
            // validation
            required,
            email,
        };
    },
    methods: {
        onExpired() {
            this.$refs.hCaptcha.reset();
        },
        onSubmit() {
            this.$refs.hCaptcha.execute();
        },
        onReset(response) {
            this.status = "";
            this.loading = true;
            axios
                .post("/auth/forgot-password", {
                    email: this.email,
                    "h-captcha-response": response,
                })
                .then((response) => {
                    this.loading = false;
                    if (response.data.success) {
                        this.variant = "success";
                        this.status = response.data.message;
                    } else {
                        this.variant = "danger";
                        this.status = response.data.message;
                    }
                    this.onExpired();
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.data.errors != undefined) {
                        if (
                            "h-captcha-response" in error.response.data.errors
                        ) {
                            this.variant = "danger";
                            this.status =
                                error.response.data.errors[
                                    "h-captcha-response"
                                ][0];
                        } else {
                            this.$refs.forgotForm.setErrors(
                                error.response.data.errors
                            );
                        }
                    }
                    this.onExpired();
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
